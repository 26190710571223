main {
	&:after,
	&:before {
		content: '';
		height: 50vh;
		width: 100%;
		position: fixed;
		left: 0;
		z-index: 7800;
		background: $primary;
		transition: .2s all ease-in-out; }
	&::after {
		top: 0;
		transform: translateY(-100%); }

	&::before {
		bottom: 0;
		transform: translateY(100%); } }



.module-transition {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 7700;
	display: none;

	.loader-bar {
		position: fixed;
		top: 50%;
		left: 0;
		width: 0;
		height: 4px;
		margin-top: -2px;
		background: $white;
		z-index: 7900; }

	.upper-panel,
	.lower-panel {
		content: '';
		height: 50vh;
		width: 100%;
		position: absolute;
		left: 0;
		z-index: 7800;
		background: $primary; }
	.upper-panel {
		top: 0;
		transform: translateY(-100%); }

	.lower-panel {
		bottom: 0;
		transform: translateY(100%); } }

.modals {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2000;
	@include displayFlex();
	@include flexAlignCenter();
	overflow: auto;

	.modal-overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2200;
		background: rgba($primary,.6);
		cursor: url(../../images/icons/white/cancel.png), auto; }
	.modal-content {
		@include defaultContainer();
		position: relative;
		z-index: 2300;
		background: rgba($secondary, .8);
		max-width: 768px;
		padding: 8px;
		color: $white;

		@media (min-width: $breakpoint-tablet) {
			padding: 32px; }

		.left-part {
			padding: 0 0 8px 0;
			@media (min-width: $breakpoint-tablet) {
				padding-right: 16px; } }

		.right-part {
			padding: 0 0 8px 0;
			@media (min-width: $breakpoint-tablet) {
				padding-left: 16px; } } } }

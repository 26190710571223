.module-fixing {
	margin: 24px auto;
	@media (min-width: $breakpoint-tablet) {
		margin: 80px auto; }

	// .fixing-container
	// 	width: 100%
	// 	text-align: left
	// 	height: 116px
	// 	overflow: hidden

	.fixing-container {
		width: 100%;
		text-align: left;
		//height: 116px
		overflow: hidden; }

	.fixing {
		padding:  8px 0;
		@include displayFlex;
		@include flexAlignCenter;
		@include flexWrap;
		flex: 1;
		//	border-right: 1px solid rgba($brown, .5)

		.label {
			font-weight: 800;
			width: 100%;
			margin-bottom: 16px;
			height: 30px;
			@media (min-width: $breakpoint-tablet) {
				height: auto; } }
		.value {
			width: 100%;
			padding:  8px 0;
			border-bottom: 1px solid rgba($brown, .5);
			font-size: normal;
			&:hover {
				font-weight: bold; } }

		.top,
		.bottom {
			@include displayFlex;
			@include flexAlignCenter;
			@include flexNoWrap;
			@include flexJustifyBetween;
			width: 100%; } }

	.more-fixing {
		position: relative;
		text-align: center;
		padding-top: 8px;
		color: rgba($primary, .5);
		font-size: 0.875em; } }

.ribbon {
	overflow: hidden; }
.ribbon-module-fixing {
	@include displayFlex;
	@include flexAlignCenter;
	@include flexNoWrap;
	margin: 24px auto;
	border-top: 1px solid rgba($brown, .5);
	border-bottom: 1px solid rgba($brown, .5);
	overflow:  hidden;

	.fixing {
		border-right: 1px solid rgba($brown, .5);
		padding: 8px;
		min-width: 120px;
		max-width: 120px;


		@include displayFlex;
		@include flexAlignCenter;
		@include flexWrap;

		.top,
		.bottom {
			@include displayFlex;
			@include flexAlignCenter;
			@include flexNoWrap;
			@include flexJustifyBetween;
			width: 100%;

			.label {
				font-weight: 800; } } } }
.is-negative {
	color: $red; }

.fixing-cta {
	text-align: center;
	margin-bottom: 40px;

	.button {
		cursor: pointer;
		padding: 8px 32px;
		background: $primary;
		border: 0;
		width: auto;
		text-shadow: 0;
		outline: none;
		color: $white;
		border-radius: 0;
		transition: .2s all ease-in-out;
		text-align: center;
		display: inline-block;
		@include flexAlignCenter();
		&:hover {
			transition: .2s all ease-in-out;
			color: $secondary;
			background: $white;
			display: inline-block; } } }

.module-announcement {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow: hidden;
	background-color: $primary;
	color: $white;
	z-index: 6000;
	p {
		font-size: 1em;
		line-height: 1.4em; }
	@media (min-width: $breakpoint-tablet) {
		width: 480px;
		height: 100vh;
		.g-75 {
			width: 100%; }
		p {
			font-size: 1.2em;
			line-height: 1.6em; } }

	.inner {
		padding: 16px;
		@media (min-width: $breakpoint-tablet) {
			padding: 32px; } } }

.module-announcement-overlay {
	position: fixed;
	top: 0;
	left: 0;
	height:  0%;
	opacity: 0;
	width: 100%;
	background: rgba($primary, .8);
	z-index:  5900;
	cursor: url(../../images/icons/white/cancel.png), auto; }

.announcement-brand {
	width: 200px;
	margin-top: 60px; }

.announcement-close {
	display: block;
	width: 100%;
	border-top: 1px solid $secondary;
	padding: 16px;
	cursor: pointer;

	@media (min-width: $breakpoint-tablet) {
		display: none; }
	img {
		width: 32px;
		height: 35px; } }

@font-face {
	font-family: 'billcorpnar-light';
	src: url('../../fonts/bill-corporate-narrow-light.eot');
	src: url('../../fonts/bill-corporate-narrow-light.eot?#iefix') format('embedded-opentype'),
	url('../../fonts/bill-corporate-narrow-light.woff2') format('woff2'),
	url('../../fonts/bill-corporate-narrow-light.woff') format('woff'),
	url('../../fonts/bill-corporate-narrow-light.ttf') format('truetype'),
	url('../../fonts/bill-corporate-narrow-light.svg#youworkforthem') format('svg') {}
	font-weight: 300;
	font-style: normal; }

@font-face {
	font-family: 'billcorpnar-book';
	src: url('../../fonts/bill-corporate-narrow-book.eot');
	src: url('../../fonts/bill-corporate-narrow-book.eot?#iefix') format('embedded-opentype'),
	url('../../fonts/bill-corporate-narrow-book.woff2') format('woff2'),
	url('../../fonts/bill-corporate-narrow-book.woff') format('woff'),
	url('../../fonts/bill-corporate-narrow-book.ttf') format('truetype'),
	url('../../fonts/bill-corporate-narrow-book.svg#youworkforthem') format('svg') {}
	font-weight: 400;
	font-style: normal; }

@font-face {
	font-family: 'billcorpnar-semi-bold';
	src: url('../../fonts/bill-corporate-narrow-semibold.eot');
	src: url('../../fonts/bill-corporate-narrow-semibold.eot?#iefix') format('embedded-opentype'),
	url('../../fonts/bill-corporate-narrow-semibold.woff2') format('woff2'),
	url('../../fonts/bill-corporate-narrow-semibold.woff') format('woff'),
	url('../../fonts/bill-corporate-narrow-semibold.ttf') format('truetype'),
	url('../../fonts/bill-corporate-narrow-semibold.svg#youworkforthem') format('svg') {}
	font-weight: 600;
	font-style: normal; }

@font-face {
	font-family: 'billcorpnar-extra-bold';
	src: url('../../fonts/bill-corporate-narrow-extrabold.eot');
	src: url('../../fonts/bill-corporate-narrow-extrabold.eot?#iefix') format('embedded-opentype'),
	url('../../fonts/bill-corporate-narrow-extrabold.woff2') format('woff2'),
	url('../../fonts/bill-corporate-narrow-extrabold.woff') format('woff'),
	url('../../fonts/bill-corporate-narrow-extrabold.ttf') format('truetype'),
	url('../../fonts/bill-corporate-narrow-extrabold.svg#youworkforthem') format('svg') {}
	font-weight: 800;
	font-style: normal; }

.legend-title {
	font-size: .6em;
	text-transform: uppercase; }
.primary-title {
	text-shadow: 0 3px 23px rgba($primary,.4); }

h1,
.primary-title {
	position: relative;
	z-index: 1800;
	color: $white;
	height: 100px;
	font-family: 'billcorpnar-extra-bold';
	font-size: 2.000em;
	font-weight: 800;
	line-height: 1em;
	margin: 0;
	padding: 0;

	@media (min-width: $breakpoint-tablet) {
		font-size: 3.50em; }

	.titles {
		position: relative; }

	.title {
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
		@include textShadow(); }

	.subtitles {
		position: relative; }

	.subtitle {
		position: absolute;
		width: 100%;
		top: 0;
		left: 0;
		@include textShadow(); }

	&.secondary-title {
		position: relative;
		z-index: 1800;
		color: $primary;
		padding-top: 64px;
		transform: translateX(-16px);
		@include textShadow();
		@media (min-width: $breakpoint-tablet) {
			transform: translateX(-32px); }
		@media (min-width: $breakpoint-desktop) {
			transform: translateX(0); } }

	&.third-title {
		position: relative;
		z-index: 1800;
		color: $primary;
		padding-top: 64px;
		text-align: right;
		transform: translateX(16px);
		@include textShadow();
		@media (min-width: $breakpoint-tablet) {
			transform: translateX(32px); }
		@media (min-width: $breakpoint-desktop) {
			transform: translateX(0); } }

	&.fourth-title {
		position: relative;
		z-index: 300;
		color: $white;
		padding-top: 64px; }

	&.fifth-title {
		position: relative;
		z-index: 1800;
		color: rgba($secondary, .15);
		font-size: 86px;
		line-height: 1em;
		text-transform: uppercase;
		transform: translateX(-16px) translateY(40px);
		@media (min-width: $breakpoint-tablet) {
			transform: translateX(-32px) translateY(40px); }
		@media (min-width: $breakpoint-desktop) {
			transform: translateX(-32px) translateY(40px); } }

	&.is-color-primary {
		color: $primary; }
	&.is-color-primary-desktop {
		@media (min-width: $breakpoint-tablet) {
			color: $primary; } } }

h2 {
	font-size: 1.250em;
	font-weight: 600;
	line-height: 1.3em;
	margin: 0;
	padding: 0;
	margin-bottom: 24px; }

h3 {
	font-family: 'billcorpnar-semi-bold';
	font-weight: 800;
	margin-bottom: 8px; }
p {
	line-height: 1.4em; }

a {
	color: inherit;
	text-decoration: none;
	&.word-break {
		word-break: break-all; } }
.default-link {
	position: relative;
	cursor: pointer;
	line-height: 1em;
	transition: .2s all ease-in-out;

	&::after {
		content: '';
		position: absolute;
		width: 0%;
		height: 4px;
		background: rgba($secondary, .3);
		bottom: 6px;
		left: 0;
		transition: .2s all ease-in-out; }

	&:hover,
	&.is-scrolled {
		&:after {
			width: 100%;
			transition: .2s all ease-in-out; } } }

.white-link {
	position: relative;
	cursor: pointer;
	line-height: 1em;
	transition: .2s all ease-in-out;
	font-weight: 600;
	color: $white;
	&::after {
		content: '';
		position: absolute;
		width: 0%;
		height: 4px;
		background: rgba($white, .5);
		bottom: 6px;
		left: 0;
		transition: .2s all ease-in-out; }

	&:hover {
		&:after {
			width: 100%;
			transition: .2s all ease-in-out; } } }
.red-link {
	position: relative;
	cursor: pointer;
	line-height: 1em;
	transition: .2s all ease-in-out;
	color: $red;
	display: inline-block;

	&::after {
		content: '';
		position: absolute;
		width: 0%;
		height: 4px;
		background: rgba($red, .5);
		bottom: 6px;
		left: 0;
		transition: .2s all ease-in-out; }
	&:hover {
		&:after {
			width: 100%;
			transition: .2s all ease-in-out; } } }

.download-link {
	position: relative;
	cursor: pointer;
	line-height: 1em;
	transition: .2s all ease-in-out;
	color: $red;
	&::after {
		content: '';
		position: absolute;
		width: 0%;
		height: 4px;
		background: rgba($red, .5);
		bottom: 6px;
		left: 0;
		transition: .2s all ease-in-out; }


	&:hover {
		&:after {
			width: 100%;
			transition: .2s all ease-in-out; } } }

.g {
	@include displayFlex();
	@include flexWrap();
	@include flexAlignStart(); }



.g-25 {
	width: 100%;
	@media (min-width: $breakpoint-tablet) {
		width: 25%; }
	@media (min-width: $breakpoint-desktop) {
		width: 25%; } }
.g-33 {
	width: 100%;
	@media (min-width: $breakpoint-tablet) {
		width: 33.3%; }
	@media (min-width: $breakpoint-desktop) {
		width: 33.3%; } }
.g-50 {
	width: 100%;
	@media (min-width: $breakpoint-tablet) {
		width: 50%; } }

.g-66 {
	width: 100%;
	@media (min-width: $breakpoint-tablet) {
		width: 66.6%; }
	@media (min-width: $breakpoint-desktop) {
		width: 66.6%; } }

.g-75 {
	width: 100%;
	@media (min-width: $breakpoint-tablet) {
		width: 75%; }
	@media (min-width: $breakpoint-desktop) {
		width: 75%; } }
.g-100 {
	width: 100%; }

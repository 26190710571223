

$white : #FFFFFF;
$black : #000000;

$primary : #122C45;
$secondary: #8B7E70;

$red : #E3170A;
$yellow : #E3B23C;
$brown : #8B7E70;

$grey-light : #000;
$grey-middle : #000;
$grey-dark : #000;
$grey-border : #000;


$dark: #000;
$darken: #000000;

$font-color: $primary;

$breakpoint-mobile: 425px;
$breakpoint-tablet: 768px;
$breakpoint-desktop: 1200px;
$breakpoint-widescreen: 1600px;

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain) */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0;
  /* ==========================================================================
   Normalize.scss settings
   @mixin ========================================================================= */ }

/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */
/* Base
   @mixin ========================================================================= */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom.
 * 3. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
 *  `em` units.
 */
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

/* HTML5 display definitions
   @mixin ========================================================================= */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none; }

/* Links
   @mixin ========================================================================= */
/**
 * Remove the gray background color from active links in IE 10.
 */
a {
  background-color: transparent; }

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */
a:active, a:hover {
  outline: 0; }

/* Text-level semantics
   @mixin ========================================================================= */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold; }

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic; }

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
h1 {
  font-size: 2em;
  margin: 0.67em 0; }

/**
 * Addresses styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
   @mixin ========================================================================= */
/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7.
 */
img {
  border: 0; }

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden; }

/* Grouping content
   @mixin ========================================================================= */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
figure {
  margin: 1em 40px; }

/**
 * Address differences between Firefox and other browsers.
 */
hr {
  box-sizing: content-box;
  height: 0; }

/**
 * Contain overflow in all browsers.
 */
pre {
  overflow: auto; }

/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

/* Forms
   @mixin ========================================================================= */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6.
 */
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
input {
  line-height: normal; }

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 *  Known issue: excess padding remains in IE 6.
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */
input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/**
 * Define consistent border, margin, and padding.
 */
fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Corrects text not wrapping in Firefox 3.
 * 4. Corrects alignment displayed oddly in IE 6/7.
 */
legend {
  border: 0;
  padding: 0; }

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  overflow: auto; }

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
optgroup {
  font-weight: bold; }

/* Tables
   @mixin ========================================================================= */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0;
  padding: 0; }

html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit; }

body {
  background-color: #FFFFFF;
  font-family: "billcorpnar-book", sans-serif !important;
  font-weight: 400;
  line-height: 1.2em;
  color: #122C45;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  overflow: hidden; }

main {
  height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch; }

.default-container {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  padding: 0 8px; }
  @media (min-width: 768px) {
    .default-container {
      padding: 0 16px; } }
  @media (min-width: 1200px) {
    .default-container {
      padding: 0; } }

.is-hidden {
  display: none !important; }

.is-overflow-hidden {
  overflow: hidden !important; }

.is-mobile-visible {
  display: auto; }
  @media (min-width: 768px) {
    .is-mobile-visible {
      display: none; } }

.is-text-right {
  text-align: right; }

.is-text-center {
  text-align: center; }

[data-href] {
  cursor: pointer; }

@font-face {
  font-family: 'billcorpnar-light';
  src: url("../../fonts/bill-corporate-narrow-light.eot");
  src: url("../../fonts/bill-corporate-narrow-light.eot?#iefix") format("embedded-opentype"), url("../../fonts/bill-corporate-narrow-light.woff2") format("woff2"), url("../../fonts/bill-corporate-narrow-light.woff") format("woff"), url("../../fonts/bill-corporate-narrow-light.ttf") format("truetype"), url("../../fonts/bill-corporate-narrow-light.svg#youworkforthem") format("svg");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: 'billcorpnar-book';
  src: url("../../fonts/bill-corporate-narrow-book.eot");
  src: url("../../fonts/bill-corporate-narrow-book.eot?#iefix") format("embedded-opentype"), url("../../fonts/bill-corporate-narrow-book.woff2") format("woff2"), url("../../fonts/bill-corporate-narrow-book.woff") format("woff"), url("../../fonts/bill-corporate-narrow-book.ttf") format("truetype"), url("../../fonts/bill-corporate-narrow-book.svg#youworkforthem") format("svg");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'billcorpnar-semi-bold';
  src: url("../../fonts/bill-corporate-narrow-semibold.eot");
  src: url("../../fonts/bill-corporate-narrow-semibold.eot?#iefix") format("embedded-opentype"), url("../../fonts/bill-corporate-narrow-semibold.woff2") format("woff2"), url("../../fonts/bill-corporate-narrow-semibold.woff") format("woff"), url("../../fonts/bill-corporate-narrow-semibold.ttf") format("truetype"), url("../../fonts/bill-corporate-narrow-semibold.svg#youworkforthem") format("svg");
  font-weight: 600;
  font-style: normal; }

@font-face {
  font-family: 'billcorpnar-extra-bold';
  src: url("../../fonts/bill-corporate-narrow-extrabold.eot");
  src: url("../../fonts/bill-corporate-narrow-extrabold.eot?#iefix") format("embedded-opentype"), url("../../fonts/bill-corporate-narrow-extrabold.woff2") format("woff2"), url("../../fonts/bill-corporate-narrow-extrabold.woff") format("woff"), url("../../fonts/bill-corporate-narrow-extrabold.ttf") format("truetype"), url("../../fonts/bill-corporate-narrow-extrabold.svg#youworkforthem") format("svg");
  font-weight: 800;
  font-style: normal; }

.legend-title {
  font-size: .6em;
  text-transform: uppercase; }

.primary-title {
  text-shadow: 0 3px 23px rgba(18, 44, 69, 0.4); }

h1,
.primary-title {
  position: relative;
  z-index: 1800;
  color: #FFFFFF;
  height: 100px;
  font-family: 'billcorpnar-extra-bold';
  font-size: 2.000em;
  font-weight: 800;
  line-height: 1em;
  margin: 0;
  padding: 0; }
  @media (min-width: 768px) {
    h1,
    .primary-title {
      font-size: 3.50em; } }
  h1 .titles,
  .primary-title .titles {
    position: relative; }
  h1 .title,
  .primary-title .title {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.16); }
  h1 .subtitles,
  .primary-title .subtitles {
    position: relative; }
  h1 .subtitle,
  .primary-title .subtitle {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.16); }
  h1.secondary-title,
  .primary-title.secondary-title {
    position: relative;
    z-index: 1800;
    color: #122C45;
    padding-top: 64px;
    transform: translateX(-16px);
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.16); }
    @media (min-width: 768px) {
      h1.secondary-title,
      .primary-title.secondary-title {
        transform: translateX(-32px); } }
    @media (min-width: 1200px) {
      h1.secondary-title,
      .primary-title.secondary-title {
        transform: translateX(0); } }
  h1.third-title,
  .primary-title.third-title {
    position: relative;
    z-index: 1800;
    color: #122C45;
    padding-top: 64px;
    text-align: right;
    transform: translateX(16px);
    text-shadow: 0 0 20px rgba(0, 0, 0, 0.16); }
    @media (min-width: 768px) {
      h1.third-title,
      .primary-title.third-title {
        transform: translateX(32px); } }
    @media (min-width: 1200px) {
      h1.third-title,
      .primary-title.third-title {
        transform: translateX(0); } }
  h1.fourth-title,
  .primary-title.fourth-title {
    position: relative;
    z-index: 300;
    color: #FFFFFF;
    padding-top: 64px; }
  h1.fifth-title,
  .primary-title.fifth-title {
    position: relative;
    z-index: 1800;
    color: rgba(139, 126, 112, 0.15);
    font-size: 86px;
    line-height: 1em;
    text-transform: uppercase;
    transform: translateX(-16px) translateY(40px); }
    @media (min-width: 768px) {
      h1.fifth-title,
      .primary-title.fifth-title {
        transform: translateX(-32px) translateY(40px); } }
    @media (min-width: 1200px) {
      h1.fifth-title,
      .primary-title.fifth-title {
        transform: translateX(-32px) translateY(40px); } }
  h1.is-color-primary,
  .primary-title.is-color-primary {
    color: #122C45; }
  @media (min-width: 768px) {
    h1.is-color-primary-desktop,
    .primary-title.is-color-primary-desktop {
      color: #122C45; } }

h2 {
  font-size: 1.250em;
  font-weight: 600;
  line-height: 1.3em;
  margin: 0;
  padding: 0;
  margin-bottom: 24px; }

h3 {
  font-family: 'billcorpnar-semi-bold';
  font-weight: 800;
  margin-bottom: 8px; }

p {
  line-height: 1.4em; }

a {
  color: inherit;
  text-decoration: none; }
  a.word-break {
    word-break: break-all; }

.default-link {
  position: relative;
  cursor: pointer;
  line-height: 1em;
  transition: .2s all ease-in-out; }
  .default-link::after {
    content: '';
    position: absolute;
    width: 0%;
    height: 4px;
    background: rgba(139, 126, 112, 0.3);
    bottom: 6px;
    left: 0;
    transition: .2s all ease-in-out; }
  .default-link:hover:after, .default-link.is-scrolled:after {
    width: 100%;
    transition: .2s all ease-in-out; }

.white-link {
  position: relative;
  cursor: pointer;
  line-height: 1em;
  transition: .2s all ease-in-out;
  font-weight: 600;
  color: #FFFFFF; }
  .white-link::after {
    content: '';
    position: absolute;
    width: 0%;
    height: 4px;
    background: rgba(255, 255, 255, 0.5);
    bottom: 6px;
    left: 0;
    transition: .2s all ease-in-out; }
  .white-link:hover:after {
    width: 100%;
    transition: .2s all ease-in-out; }

.red-link {
  position: relative;
  cursor: pointer;
  line-height: 1em;
  transition: .2s all ease-in-out;
  color: #E3170A;
  display: inline-block; }
  .red-link::after {
    content: '';
    position: absolute;
    width: 0%;
    height: 4px;
    background: rgba(227, 23, 10, 0.5);
    bottom: 6px;
    left: 0;
    transition: .2s all ease-in-out; }
  .red-link:hover:after {
    width: 100%;
    transition: .2s all ease-in-out; }

.download-link {
  position: relative;
  cursor: pointer;
  line-height: 1em;
  transition: .2s all ease-in-out;
  color: #E3170A; }
  .download-link::after {
    content: '';
    position: absolute;
    width: 0%;
    height: 4px;
    background: rgba(227, 23, 10, 0.5);
    bottom: 6px;
    left: 0;
    transition: .2s all ease-in-out; }
  .download-link:hover:after {
    width: 100%;
    transition: .2s all ease-in-out; }

.field-group {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap; }
  .field-group label {
    width: 100%;
    display: none; }
  .field-group .field-input {
    background: #FFFFFF;
    outline: none;
    padding: 16px;
    color: #122C45;
    border: 0;
    width: 100%;
    margin-bottom: 16px;
    border-radius: 0; }
  .field-group .field-textarea {
    background: #FFFFFF;
    outline: none;
    padding: 16px;
    color: #122C45;
    border: 0;
    width: 100%;
    margin-bottom: 16px;
    color: #122C45;
    border-radius: 0; }
  .field-group button {
    padding: 8px 16px;
    background: #8B7E70;
    border: 0;
    text-shadow: 0;
    outline: none;
    color: #FFFFFF;
    border-radius: 0;
    transition: .2s all ease-in-out; }
    .field-group button:hover {
      transition: .2s all ease-in-out;
      background: #FFFFFF;
      color: #8B7E70; }

.g {
  display: flex;
  display: -ms-flexbox;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  align-items: flex-start;
  -webkit-align-items: flex-start;
  -ms-flex-align: flex-start; }

.g-25 {
  width: 100%; }
  @media (min-width: 768px) {
    .g-25 {
      width: 25%; } }
  @media (min-width: 1200px) {
    .g-25 {
      width: 25%; } }

.g-33 {
  width: 100%; }
  @media (min-width: 768px) {
    .g-33 {
      width: 33.3%; } }
  @media (min-width: 1200px) {
    .g-33 {
      width: 33.3%; } }

.g-50 {
  width: 100%; }
  @media (min-width: 768px) {
    .g-50 {
      width: 50%; } }

.g-66 {
  width: 100%; }
  @media (min-width: 768px) {
    .g-66 {
      width: 66.6%; } }
  @media (min-width: 1200px) {
    .g-66 {
      width: 66.6%; } }

.g-75 {
  width: 100%; }
  @media (min-width: 768px) {
    .g-75 {
      width: 75%; } }
  @media (min-width: 1200px) {
    .g-75 {
      width: 75%; } }

.g-100 {
  width: 100%; }

.alert {
  margin-bottom: 16px; }

.alert-error {
  color: #E3170A; }

nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 4000;
  height: 48px;
  background: white; }
  @media (min-width: 1200px) {
    nav {
      transform: translateY(0) !important; } }
  nav .navbar {
    position: relative;
    height: 100%;
    display: flex;
    display: -ms-flexbox;
    align-items: flex-start;
    -webkit-align-items: flex-start;
    -ms-flex-align: flex-start;
    flex-wrap: nowrap;
    -webkit-flex-wrap: nowrap;
    justify-content: start;
    -webkit-justify-content: start;
      -webkit-justify-content--ms-flex-pack: start;
    background: #FFFFFF;
    transition: .3s all ease-in; }
    @media (min-width: 768px) {
      nav .navbar {
        justify-content: space-between;
        -webkit-justify-content: space-between;
        -ms-flex-pack: space-between;
        transition: .3s all ease-in; } }
  nav .brand {
    height: 48px;
    width: auto;
    overflow: hidden;
    cursor: pointer;
    transition: .2s all ease-in;
    width: 160px; }
    nav .brand:hover {
      width: 180px;
      transition: .2s all ease-in; }
    nav .brand img {
      width: auto;
      padding: 8px;
      height: 48px; }

.menu-mobile {
  position: absolute;
  right: 0;
  width: 48px;
  height: 48px;
  cursor: pointer; }
  @media (min-width: 1200px) {
    .menu-mobile {
      display: none; } }
  .menu-mobile:hover .bar[data-type="1"] {
    transition: all .2s ease; }
  .menu-mobile:hover .bar[data-type="1"] {
    transition: all .2s ease; }
  .menu-mobile:hover .bar[data-type="3"] {
    transition: all .2s ease; }
  .menu-mobile:hover .bar[data-type="3"] {
    transition: all .2s ease; }
  .menu-mobile .bars {
    position: absolute;
    width: 24px;
    height: 16px;
    top: 16px;
    left: 12px; }
    .menu-mobile .bars .bar {
      width: 100%;
      height: 2px;
      background: #122C45;
      position: absolute;
      transition: all .2s ease;
      transform-origin: 50% 50%; }
      .menu-mobile .bars .bar[data-type="1"] {
        top: 0;
        left: 0; }
      .menu-mobile .bars .bar[data-type="2"] {
        top: 6px;
        left: 0; }
      .menu-mobile .bars .bar[data-type="3"] {
        top: 12px;
        left: 0; }

.primary-navigation {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 290px;
  background: #FFFFFF;
  text-align: center;
  transform: translateY(-290px);
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  justify-content: flex-end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: flex-end;
  text-align: center; }
  @media (min-width: 1200px) {
    .primary-navigation {
      height: 100%;
      position: relative;
      transform: translateY(0);
      padding: 0; } }
  .primary-navigation li {
    display: inline-block;
    padding: 8px 0 8px 16px;
    width: 100%;
    cursor: pointer; }
    @media (min-width: 1200px) {
      .primary-navigation li {
        width: auto;
        padding: 0 16px;
        display: flex;
        display: -ms-flexbox;
        align-items: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        height: 100%; } }
    .primary-navigation li .button {
      cursor: pointer;
      padding: 8px 32px;
      height: 100%;
      background: #122C45;
      border: 0;
      width: auto;
      text-shadow: 0;
      outline: none;
      color: #FFFFFF;
      border-radius: 0;
      transition: .2s all ease-in-out;
      text-align: center;
      display: inline-block;
      box-shadow: 0 3px 23px rgba(0, 0, 0, 0.4);
      align-items: center;
      -webkit-align-items: center;
      -ms-flex-align: center; }
      .primary-navigation li .button:hover {
        transition: .2s all ease-in-out;
        color: #8B7E70;
        background: #FFFFFF; }
      @media (min-width: 1200px) {
        .primary-navigation li .button {
          display: flex;
          display: -ms-flexbox; } }

.secondary-navigation {
  width: 100%;
  background: rgba(255, 255, 255, 0.6) pcontac;
  padding: 8px 0;
  z-index: 6500;
  left: 0; }
  .secondary-navigation ul {
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    justify-content: start;
    -webkit-justify-content: start;
      -webkit-justify-content--ms-flex-pack: start; }
  .secondary-navigation li {
    display: inline-block;
    padding: 0 16px; }
  .secondary-navigation .arrow-left {
    width: 32px;
    height: 32px; }

footer {
  width: 100%;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  border-top: 1px solid rgba(139, 126, 112, 0.5);
  margin-top: 32px !important;
  padding: 16px 0;
  margin-bottom: 64px; }
  @media (min-width: 768px) {
    footer {
      padding: 32px 0; } }
  footer .nav ul li {
    display: inline-block;
    padding: 0 0 16px 0;
    width: 100%; }
    @media (min-width: 768px) {
      footer .nav ul li {
        width: auto;
        padding: 0 8px 0 0; } }
  footer .social ul li {
    display: inline-block;
    padding: 0  0 0 8px; }
  footer .gottex {
    font-weight: 800; }
  footer .social img {
    width: 32px;
    height: 32px; }

.blog-item {
  margin-bottom: 40px;
  padding-right: 0; }
  @media (min-width: 768px) {
    .blog-item {
      padding-right: 32px; } }
  @media (min-width: 1200px) {
    .blog-item {
      padding-right: 60px; } }
  .blog-item .download-link {
    margin-top: 16px;
    display: inline-block; }

.modals {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2000;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  overflow: auto; }
  .modals .modal-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2200;
    background: rgba(18, 44, 69, 0.6);
    cursor: url(../../images/icons/white/cancel.png), auto; }
  .modals .modal-content {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    padding: 0 8px;
    position: relative;
    z-index: 2300;
    background: rgba(139, 126, 112, 0.8);
    max-width: 768px;
    padding: 8px;
    color: #FFFFFF; }
    @media (min-width: 768px) {
      .modals .modal-content {
        padding: 0 16px; } }
    @media (min-width: 1200px) {
      .modals .modal-content {
        padding: 0; } }
    @media (min-width: 768px) {
      .modals .modal-content {
        padding: 32px; } }
    .modals .modal-content .left-part {
      padding: 0 0 8px 0; }
      @media (min-width: 768px) {
        .modals .modal-content .left-part {
          padding-right: 16px; } }
    .modals .modal-content .right-part {
      padding: 0 0 8px 0; }
      @media (min-width: 768px) {
        .modals .modal-content .right-part {
          padding-left: 16px; } }

.module-image {
  position: relative;
  height: 680px;
  border-left: 16px solid #FFFFFF;
  border-right: 16px solid #FFFFFF; }
  @media (min-width: 768px) {
    .module-image {
      border-left: 32px solid #FFFFFF;
      border-right: 32px solid #FFFFFF; } }
  .module-image .backgrounds-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden; }
  .module-image .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1200;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; }
    .module-image .background-image.background-primary {
      background-color: #122C45; }
  .module-image .brush-image {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 34px;
    width: 100%;
    background-size: 200px;
    background-position: bottom left;
    background-repeat: repeat-x;
    z-index: 1250; }
    .module-image .brush-image.brush-top {
      bottom: auto;
      top: 0; }
    .module-image .brush-image.brush-primary {
      height: 300px;
      width: 100%;
      background-size: 600px; }
    .module-image .brush-image.brush-click-throught {
      pointer-events: none; }
  .module-image .header-content {
    padding-top: 200px;
    z-index: 1300; }

.primary-module-image {
  border-top: 48px solid #FFFFFF; }

.secondary-module-image {
  border-top: 48px solid #FFFFFF;
  height: 400px;
  text-align: center; }
  @media (min-width: 768px) {
    .secondary-module-image {
      height: 680px;
      text-align: left; } }

.bg-homepage-header-switzerland {
  background-image: url(../../images/background/switzerland/header@mobile.jpg); }
  @media (min-width: 768px) {
    .bg-homepage-header-switzerland {
      background-image: url(../../images/background/switzerland/header@tablet.jpg); } }
  @media (min-width: 1200px) {
    .bg-homepage-header-switzerland {
      background-image: url(../../images/background/switzerland/header@desktop.jpg); } }
  @media (min-width: 1600px) {
    .bg-homepage-header-switzerland {
      background-image: url(../../images/background/switzerland/header@widescreen.jpg); } }

.bg-homepage-header-sweden {
  background-image: url(../../images/background/sweden/header@mobile.jpg); }
  @media (min-width: 768px) {
    .bg-homepage-header-sweden {
      background-image: url(../../images/background/sweden/header@tablet.jpg); } }
  @media (min-width: 1200px) {
    .bg-homepage-header-sweden {
      background-image: url(../../images/background/sweden/header@desktop.jpg); } }
  @media (min-width: 1600px) {
    .bg-homepage-header-sweden {
      background-image: url(../../images/background/sweden/header@widescreen.jpg); } }

.bg-homepage-switzerland {
  background-image: url(../../images/background/switzerland/about@mobile.jpg); }
  @media (min-width: 768px) {
    .bg-homepage-switzerland {
      background-image: url(../../images/background/switzerland/about@tablet.jpg); } }
  @media (min-width: 1200px) {
    .bg-homepage-switzerland {
      background-image: url(../../images/background/switzerland/about@desktop.jpg); } }
  @media (min-width: 1600px) {
    .bg-homepage-switzerland {
      background-image: url(../../images/background/switzerland/about@widescreen.jpg); } }

.bg-homepage-zurich {
  background-image: url(../../images/background/zurich/about@mobile.jpg); }
  @media (min-width: 768px) {
    .bg-homepage-zurich {
      background-image: url(../../images/background/zurich/about@tablet.jpg); } }
  @media (min-width: 1200px) {
    .bg-homepage-zurich {
      background-image: url(../../images/background/zurich/about@desktop.jpg); } }
  @media (min-width: 1600px) {
    .bg-homepage-zurich {
      background-image: url(../../images/background/zurich/about@widescreen.jpg); } }

.bg-homepage-sweden {
  background-image: url(../../images/background/sweden/about@mobile.jpg); }
  @media (min-width: 768px) {
    .bg-homepage-sweden {
      background-image: url(../../images/background/sweden/about@tablet.jpg); } }
  @media (min-width: 1200px) {
    .bg-homepage-sweden {
      background-image: url(../../images/background/sweden/about@desktop.jpg); } }
  @media (min-width: 1600px) {
    .bg-homepage-sweden {
      background-image: url(../../images/background/sweden/about@widescreen.jpg); } }

.bg-homepage-norway {
  background-image: url(../../images/background/norway/header@mobile.jpg); }
  @media (min-width: 768px) {
    .bg-homepage-norway {
      background-image: url(../../images/background/norway/header@tablet.jpg); } }
  @media (min-width: 1200px) {
    .bg-homepage-norway {
      background-image: url(../../images/background/norway/header@desktop.jpg); } }
  @media (min-width: 1600px) {
    .bg-homepage-norway {
      background-image: url(../../images/background/norway/header@widescreen.jpg); } }

.bg-homepage-team {
  background-image: url(../../images/background/team@tablet.jpg); }
  @media (min-width: 1200px) {
    .bg-homepage-team {
      background-image: url(../../images/background/team@desktop.jpg); } }
  @media (min-width: 1600px) {
    .bg-homepage-team {
      background-image: url(../../images/background/team@widescreen.jpg); } }

.bg-contact-header {
  background-image: url(../../images/background/switzerland/about@mobile.jpg); }
  @media (min-width: 768px) {
    .bg-contact-header {
      background-image: url(../../images/background/switzerland/about@tablet.jpg); } }
  @media (min-width: 1200px) {
    .bg-contact-header {
      background-image: url(../../images/background/switzerland/about@desktop.jpg); } }
  @media (min-width: 1600px) {
    .bg-contact-header {
      background-image: url(../../images/background/switzerland/about@widescreen.jpg); } }

.bg-team-header {
  background-image: url(../../images/background/team@tablet.jpg); }
  @media (min-width: 1200px) {
    .bg-team-header {
      background-image: url(../../images/background/team@desktop.jpg); } }
  @media (min-width: 1600px) {
    .bg-team-header {
      background-image: url(../../images/background/team@widescreen.jpg); } }

.bg-switzerland-header {
  background-image: url(../../images/background/switzerland/market@mobile.jpg); }
  @media (min-width: 768px) {
    .bg-switzerland-header {
      background-image: url(../../images/background/switzerland/market@tablet.jpg); } }
  @media (min-width: 1200px) {
    .bg-switzerland-header {
      background-image: url(../../images/background/switzerland/market@desktop.jpg); } }
  @media (min-width: 1600px) {
    .bg-switzerland-header {
      background-image: url(../../images/background/switzerland/market@widescreen.jpg); } }

.bg-blog-header {
  background-image: url(../../images/background/sweden/header@mobile.jpg); }
  @media (min-width: 768px) {
    .bg-blog-header {
      background-image: url(../../images/background/sweden/header@tablet.jpg); } }
  @media (min-width: 1200px) {
    .bg-blog-header {
      background-image: url(../../images/background/sweden/header@desktop.jpg); } }
  @media (min-width: 1600px) {
    .bg-blog-header {
      background-image: url(../../images/background/sweden/header@widescreen.jpg); } }

.bg-fixing-header {
  background-image: url(../../images/background/blog@mobile.jpg); }
  @media (min-width: 768px) {
    .bg-fixing-header {
      background-image: url(../../images/background/blog@tablet.jpg); } }
  @media (min-width: 1200px) {
    .bg-fixing-header {
      background-image: url(../../images/background/blog@desktop.jpg); } }
  @media (min-width: 1600px) {
    .bg-fixing-header {
      background-image: url(../../images/background/blog@widescreen.jpg); } }

.primary-module-text {
  position: relative;
  z-index: 2000;
  padding: 32px;
  margin-top: -200px;
  background: #122C45;
  color: #FFFFFF;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); }
  @media (min-width: 768px) {
    .primary-module-text {
      padding: 40px; } }
  .primary-module-text .left-part {
    padding: 0 0 8px 0; }
    @media (min-width: 768px) {
      .primary-module-text .left-part {
        padding-right: 16px; } }
  .primary-module-text .right-part {
    padding: 0 0 8px 0; }
    @media (min-width: 768px) {
      .primary-module-text .right-part {
        padding-left: 16px; } }

.secondary-module-text {
  position: relative;
  z-index: 2000;
  padding: 32px;
  margin-top: -200px;
  margin-bottom: 16px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  color: #FFFFFF; }
  @media (min-width: 768px) {
    .secondary-module-text {
      margin-bottom: 80px;
      padding: 40px; } }
  .secondary-module-text p {
    margin-bottom: 16px; }
  .secondary-module-text[data-anim-sweden] {
    background: #E3B23C; }
  .secondary-module-text[data-anim-switzerand] {
    background: #8B7E70; }
  .secondary-module-text .left-part {
    padding: 0 0 8px 0; }
    @media (min-width: 768px) {
      .secondary-module-text .left-part {
        padding-right: 16px; } }
  .secondary-module-text .right-part {
    padding: 0 0 8px 0; }
    @media (min-width: 768px) {
      .secondary-module-text .right-part {
        padding-left: 16px; } }

.default-module-text {
  margin-bottom: 40px; }
  .default-module-text p {
    margin-bottom: 16px; }
  .default-module-text ul.default-list li {
    padding: 0;
    margin: 0;
    position: relative;
    padding-left: 16px;
    padding-bottom: 8px; }
    .default-module-text ul.default-list li:after {
      content: '';
      position: absolute;
      top: 8px;
      left: 0;
      width: 4px;
      height: 4px;
      background: #122C45;
      border-radius: 50%; }
  .default-module-text ul.two-cols-list li {
    width: 48%;
    display: inline-block; }
  .default-module-text .left-part {
    padding: 0 0 32px 0; }
    @media (min-width: 768px) {
      .default-module-text .left-part {
        padding-right: 16px; } }
  .default-module-text .middle-part {
    padding: 0 0 32px 0; }
    @media (min-width: 768px) {
      .default-module-text .middle-part {
        padding-right: 16px;
        padding-left: 16px; } }
  .default-module-text .right-part {
    padding: 0 0 32px 0; }
    @media (min-width: 768px) {
      .default-module-text .right-part {
        padding-left: 16px; } }

.third-module-text {
  position: relative;
  z-index: 2000;
  padding: 40px;
  background: #122C45;
  color: #FFFFFF;
  margin-right: 0;
  margin-bottom: 40px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); }
  @media (min-width: 768px) {
    .third-module-text {
      margin-right: 32px; } }
  .third-module-text .left-part {
    padding: 0 0 8px 0; }
    @media (min-width: 768px) {
      .third-module-text .left-part {
        padding-right: 16px; } }
  .third-module-text .right-part {
    padding: 0 0 8px 0; }
    @media (min-width: 768px) {
      .third-module-text .right-part {
        padding-left: 16px; } }
  .third-module-text p {
    margin-bottom: 16px; }
  .third-module-text ul.default-list li {
    padding: 0;
    margin: 0;
    position: relative;
    padding-left: 16px;
    padding-bottom: 8px; }
    .third-module-text ul.default-list li:after {
      content: '';
      position: absolute;
      top: 8px;
      left: 0;
      width: 4px;
      height: 4px;
      background: #FFFFFF;
      border-radius: 50%; }

.fourth-module-text {
  position: relative;
  z-index: 2000;
  padding: 40px;
  margin-top: 0;
  background: #122C45;
  color: #FFFFFF;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); }
  @media (min-width: 768px) {
    .fourth-module-text {
      margin-top: -200px; } }
  .fourth-module-text .left-part {
    padding: 0 0 8px 0; }
    @media (min-width: 768px) {
      .fourth-module-text .left-part {
        padding-right: 16px; } }
  .fourth-module-text .right-part {
    padding: 0 0 8px 0; }
    @media (min-width: 768px) {
      .fourth-module-text .right-part {
        padding-left: 16px; } }

.experience-selection {
  position: absolute;
  bottom: 0;
  right: 0;
  background: #FFFFFF;
  z-index: 2000;
  text-align: center;
  padding: 0;
  box-shadow: 0 -6px 16px rgba(0, 0, 0, 0.23);
  font-size: 0.875em;
  font-weight: 800;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  display: flex;
  display: -ms-flexbox;
  justify-content: start;
  -webkit-justify-content: start;
    -webkit-justify-content--ms-flex-pack: start;
  transform: rotate(90deg) translateY(17px);
  width: 680px;
  height: 32px;
  transform-origin: 100% 100%;
  background: transparent;
  box-shadow: none;
  color: #122C45;
  padding-left: 64px; }
  @media (min-width: 768px) {
    .experience-selection {
      position: fixed;
      transform: rotate(90deg) translateY(48px);
      width: 100vh; } }

.switch-button {
  cursor: pointer;
  padding: 0;
  margin: 0;
  border: 0;
  transform: rotate(0);
  cursor: pointer;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  justify-content: start;
  -webkit-justify-content: start;
    -webkit-justify-content--ms-flex-pack: start;
  height: 32px;
  padding: 16px;
  width: auto;
  transition: all .3s ease-in-out;
  text-align: center;
  margin-right: 16px;
  position: relative; }
  .switch-button::after {
    content: '';
    position: absolute;
    width: 0%;
    height: 4px;
    background: rgba(227, 23, 10, 0.5);
    bottom: 0;
    left: 0;
    transition: .2s all ease-in-out; }
  .switch-button[data-trigger-switzerland]:after {
    background: rgba(227, 23, 10, 0.5); }
  .switch-button[data-trigger-sweden]:after {
    background: rgba(227, 178, 60, 0.5); }
  .switch-button:hover[data-trigger-switzerland]:after, .switch-button.is-active[data-trigger-switzerland]:after {
    width: 100%;
    transition: .2s all ease-in-out; }
  .switch-button:hover[data-trigger-sweden]:after, .switch-button.is-active[data-trigger-sweden]:after {
    width: 100%;
    transition: .2s all ease-in-out; }
  .switch-button span {
    display: none;
    padding: 0 6px; }

.module-fixing {
  margin: 24px auto; }
  @media (min-width: 768px) {
    .module-fixing {
      margin: 80px auto; } }
  .module-fixing .fixing-container {
    width: 100%;
    text-align: left;
    overflow: hidden; }
  .module-fixing .fixing {
    padding: 8px 0;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    flex: 1; }
    .module-fixing .fixing .label {
      font-weight: 800;
      width: 100%;
      margin-bottom: 16px;
      height: 30px; }
      @media (min-width: 768px) {
        .module-fixing .fixing .label {
          height: auto; } }
    .module-fixing .fixing .value {
      width: 100%;
      padding: 8px 0;
      border-bottom: 1px solid rgba(139, 126, 112, 0.5);
      font-size: normal; }
      .module-fixing .fixing .value:hover {
        font-weight: bold; }
    .module-fixing .fixing .top,
    .module-fixing .fixing .bottom {
      display: flex;
      display: -ms-flexbox;
      align-items: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      -ms-flex-pack: space-between;
      width: 100%; }
  .module-fixing .more-fixing {
    position: relative;
    text-align: center;
    padding-top: 8px;
    color: rgba(18, 44, 69, 0.5);
    font-size: 0.875em; }

.ribbon {
  overflow: hidden; }

.ribbon-module-fixing {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  margin: 24px auto;
  border-top: 1px solid rgba(139, 126, 112, 0.5);
  border-bottom: 1px solid rgba(139, 126, 112, 0.5);
  overflow: hidden; }
  .ribbon-module-fixing .fixing {
    border-right: 1px solid rgba(139, 126, 112, 0.5);
    padding: 8px;
    min-width: 120px;
    max-width: 120px;
    display: flex;
    display: -ms-flexbox;
    align-items: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    flex-wrap: wrap;
    -webkit-flex-wrap: wrap; }
    .ribbon-module-fixing .fixing .top,
    .ribbon-module-fixing .fixing .bottom {
      display: flex;
      display: -ms-flexbox;
      align-items: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
      justify-content: space-between;
      -webkit-justify-content: space-between;
      -ms-flex-pack: space-between;
      width: 100%; }
      .ribbon-module-fixing .fixing .top .label,
      .ribbon-module-fixing .fixing .bottom .label {
        font-weight: 800; }

.is-negative {
  color: #E3170A; }

.fixing-cta {
  text-align: center;
  margin-bottom: 40px; }
  .fixing-cta .button {
    cursor: pointer;
    padding: 8px 32px;
    background: #122C45;
    border: 0;
    width: auto;
    text-shadow: 0;
    outline: none;
    color: #FFFFFF;
    border-radius: 0;
    transition: .2s all ease-in-out;
    text-align: center;
    display: inline-block;
    align-items: center;
    -webkit-align-items: center;
    -ms-flex-align: center; }
    .fixing-cta .button:hover {
      transition: .2s all ease-in-out;
      color: #8B7E70;
      background: #FFFFFF;
      display: inline-block; }

.module-contact {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  color: #FFFFFF;
  position: relative;
  z-index: 2700; }
  .module-contact .image {
    width: 64px;
    max-width: 64px;
    min-width: 64px;
    height: 64px;
    border-radius: 50%;
    background-size: 100%;
    background-position: center;
    margin-right: 16px;
    flex: 1; }
  .module-contact .contact {
    flex: 1; }
    .module-contact .contact li {
      word-break: normal;
      padding: 4px 0; }
  @media (min-width: 768px) {
    .module-contact {
      flex-wrap: wrap;
      -webkit-flex-wrap: wrap; }
      .module-contact .image {
        width: 100%;
        margin-bottom: 8px; }
      .module-contact .contact {
        flex: 100%; } }

.primary-contact {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  justify-content: start;
  -webkit-justify-content: start;
    -webkit-justify-content--ms-flex-pack: start;
  padding: 16px;
  width: 100%;
  min-width: 360px;
  max-width: 420px;
  background: #122C45;
  flex-wrap: wrap;
  -webkit-flex-wrap: wrap;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); }
  @media (min-width: 768px) {
    .primary-contact {
      transform: translateX(-32px) translateY(-32px);
      flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap;
      width: auto;
      min-width: 360px;
      max-width: 420px; } }
  @media (min-width: 1200px) {
    .primary-contact {
      transform: translateX(-32px) translateY(-64px); } }

.secondary-contact {
  margin-bottom: 16px; }
  .secondary-contact .image {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); }

.default-contact {
  margin-bottom: 16px;
  color: #122C45;
  justify-content: start;
  -webkit-justify-content: start;
    -webkit-justify-content--ms-flex-pack: start;
  margin-bottom: 16px; }
  @media (min-width: 768px) {
    .default-contact {
      margin-bottom: 40px; } }
  .default-contact .contact-role {
    font-family: 'billcorpnar-semi-bold';
    color: #8B7E70;
    font-size: 0.875em; }

.jobs-offer {
  display: flex;
  justify-content: space-between; }

.job-offer {
  position: relative;
  background: #FFFFFF;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  padding: 16px; }
  @media (min-width: 768px) {
    .job-offer {
      padding: 32px; } }
  .job-offer .contact {
    position: relative; }
  .job-offer .cta {
    position: relative;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    margin-top: 8px; }
    @media (min-width: 768px) {
      .job-offer .cta {
        position: absolute;
          position-margin-top: 0; } }
  .job-offer .icon-linkedin {
    height: 20px; }
    .job-offer .icon-linkedin img {
      width: auto;
      height: 100%; }
  .job-offer .button {
    background: #122C45;
    padding: 0 16px;
    border-radius: 4px;
    font-size: 12px;
    color: #FFF;
    font-weight: bold;
    line-height: 1em;
    height: 25px;
    display: flex;
    align-items: center;
    padding-top: 2px; }

.contact-select {
  height: 53px;
  border-radius: 0;
  -webkit-appearance: none;
  -webkit-border-radius: 0px; }

.module-newsletter {
  position: relative;
  z-index: 1400;
  padding: 32px;
  background: #122C45;
  margin-top: -20px;
  color: #FFFFFF;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3); }
  @media (min-width: 1200px) {
    .module-newsletter {
      padding: 40px; } }
  .module-newsletter h3 {
    margin-bottom: 16px; }

.mc-field-group {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  border-radius: 0; }
  .mc-field-group label {
    display: none; }
  .mc-field-group input[type="email"] {
    background: #FFFFFF;
    outline: none;
    padding: 16px;
    color: #122C45;
    border: 0;
    flex: 2;
    border-radius: 0; }
  .mc-field-group input[type="submit"] {
    outline: none;
    border: 0;
    padding: 16px 8px;
    background: #8B7E70;
    flex: 1;
    font-weight: 800;
    font-size: 16px;
    border-radius: 0;
    transition: .2s all ease-in-out; }
    .mc-field-group input[type="submit"]:hover {
      transition: .2s all ease-in-out;
      background: #FFFFFF;
      color: #8B7E70; }

.module-hours {
  margin-top: 40px;
  overflow: hidden;
  background-color: #122C45;
  height: 330px;
  text-align: center; }
  @media (min-width: 768px) {
    .module-hours {
      height: 680px;
      text-align: left; } }
  .module-hours [data-anim-hours] {
    display: none; }
    @media (min-width: 768px) {
      .module-hours [data-anim-hours] {
        background-size: 70% 100%;
        opacity: .5;
        background-position: center;
        display: block; } }
    @media (min-width: 1200px) {
      .module-hours [data-anim-hours] {
        background-size: 50% 100%; } }

.clock {
  margin: 0 auto;
  transform: translateY(-64px);
  width: 300px;
  height: 300px;
  position: relative;
  border-radius: 50%;
  background: #FFFFFF;
  border: 3px solid #8B7E70;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  position: relative;
  z-index: 3200; }
  @media (min-width: 768px) {
    .clock {
      transform: translateY(-200px); } }
  .clock .layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    height: 300px;
    transform-origin: 50% 50%; }
    .clock .layer.base {
      background: url(../../images/clock/base.svg);
      z-index: 100; }
    .clock .layer.hours {
      background: url(../../images/clock/hours.svg);
      z-index: 110; }
    .clock .layer.minutes {
      background: url(../../images/clock/minutes.svg);
      z-index: 120; }
    .clock .layer.seconds {
      z-index: 130; }

[data-change-fill] {
  fill: #8B7E70; }

.module-announcement {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: #122C45;
  color: #FFFFFF;
  z-index: 6000; }
  .module-announcement p {
    font-size: 1em;
    line-height: 1.4em; }
  @media (min-width: 768px) {
    .module-announcement {
      width: 480px;
      height: 100vh; }
      .module-announcement .g-75 {
        width: 100%; }
      .module-announcement p {
        font-size: 1.2em;
        line-height: 1.6em; } }
  .module-announcement .inner {
    padding: 16px; }
    @media (min-width: 768px) {
      .module-announcement .inner {
        padding: 32px; } }

.module-announcement-overlay {
  position: fixed;
  top: 0;
  left: 0;
  height: 0%;
  opacity: 0;
  width: 100%;
  background: rgba(18, 44, 69, 0.8);
  z-index: 5900;
  cursor: url(../../images/icons/white/cancel.png), auto; }

.announcement-brand {
  width: 200px;
  margin-top: 60px; }

.announcement-close {
  display: block;
  width: 100%;
  border-top: 1px solid #8B7E70;
  padding: 16px;
  cursor: pointer; }
  @media (min-width: 768px) {
    .announcement-close {
      display: none; } }
  .announcement-close img {
    width: 32px;
    height: 35px; }

.module-loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-position: center;
  background-size: 200px;
  background-repeat: no-repeat;
  background-color: #122C45;
  z-index: 8000;
  padding: 16px;
  display: flex;
  display: -ms-flexbox;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  justify-content: center;
  -webkit-justify-content: center;
    -webkit-justify-content--ms-flex-pack: center; }
  .module-loader svg {
    width: 400px;
    fill: #FFFFFF; }
  .module-loader .color-brand {
    fill: #8B7E70; }

[data-loader-anim-path] {
  opacity: 0; }

main:after, main:before {
  content: '';
  height: 50vh;
  width: 100%;
  position: fixed;
  left: 0;
  z-index: 7800;
  background: #122C45;
  transition: .2s all ease-in-out; }

main::after {
  top: 0;
  transform: translateY(-100%); }

main::before {
  bottom: 0;
  transform: translateY(100%); }

.module-transition {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 7700;
  display: none; }
  .module-transition .loader-bar {
    position: fixed;
    top: 50%;
    left: 0;
    width: 0;
    height: 4px;
    margin-top: -2px;
    background: #FFFFFF;
    z-index: 7900; }
  .module-transition .upper-panel,
  .module-transition .lower-panel {
    content: '';
    height: 50vh;
    width: 100%;
    position: absolute;
    left: 0;
    z-index: 7800;
    background: #122C45; }
  .module-transition .upper-panel {
    top: 0;
    transform: translateY(-100%); }
  .module-transition .lower-panel {
    bottom: 0;
    transform: translateY(100%); }

.module-error {
  text-align: center; }
  .module-error h1 {
    color: #122C45; }

.award {
  width: 100%;
  display: flex;
  display: -ms-flexbox;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  padding: 16px 0; }
  @media (min-width: 768px) {
    .award {
      justify-content: start;
      -webkit-justify-content: start;
        -webkit-justify-content--ms-flex-pack: start;
      flex-wrap: wrap;
      -webkit-flex-wrap: wrap; } }
  .award .label {
    color: #8B7E70;
    text-align: left;
    width: 100%;
    margin-bottom: 40px; }
    @media (min-width: 768px) {
      .award .label {
        text-align: center; } }
  .award .image {
    padding: 16px;
    min-width: 160px; }
    .award .image img {
      width: 100%; }

.market-item {
  cursor: pointer;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  flex-wrap: nowrap;
  -webkit-flex-wrap: nowrap;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  -ms-flex-pack: space-between;
  padding: 16px 0;
  opacity: .4;
  border-bottom: 1px solid transparent;
  transition: .2s all ease-in; }
  @media (min-width: 768px) {
    .market-item {
      flex-wrap: wrap;
      -webkit-flex-wrap: wrap; } }
  @media (min-width: 1200px) {
    .market-item {
      flex-wrap: nowrap;
      -webkit-flex-wrap: nowrap; } }
  .market-item.is-open {
    opacity: 1;
    transition: .2s all ease-in; }
  .market-item.is-active {
    border-bottom: 1px solid #FFFFFF;
    transition: .2s all ease-in; }
  .market-item:hover {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    transition: .2s all ease-in; }
  .market-item .label {
    font-weight: 600; }

.name {
  position: relative;
  z-index: 1500;
  text-align: center;
  color: #FFFFFF;
  font-weight: 800;
  background: rgba(18, 44, 69, 0.3);
  padding: 6px;
  display: inline-block; }

.management-names {
  position: absolute;
  bottom: 100px;
  left: 0;
  z-index: 1500;
  text-align: center;
  font-weight: 800;
  width: 100%;
  min-width: 1200px; }
  .management-names .g-25 {
    width: 25%; }

.team-image {
  height: 680px;
  min-width: 1200px;
  width: 100%; }

.manager-biography p {
  margin-bottom: 16px; }

.management-cta {
  position: absolute;
  bottom: 60px;
  left: 0;
  z-index: 1652;
  width: 100%;
  text-align: center; }
  .management-cta .button {
    cursor: pointer;
    padding: 16px 32px;
    background: #FFFFFF;
    border: 0;
    width: auto;
    text-shadow: 0;
    outline: none;
    color: #122C45;
    border-radius: 0;
    transition: .2s all ease-in-out;
    text-align: center;
    display: inline-block; }
    .management-cta .button:hover {
      transition: .2s all ease-in-out;
      color: #8B7E70; }

.swipe-tuto {
  background: rgba(18, 44, 69, 0.6);
  color: #FFFFFF;
  position: absolute;
  padding: 8px;
  bottom: 7px;
  right: 0;
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  -webkit-align-items: center;
  -ms-flex-align: center; }
  @media (min-width: 1200px) {
    .swipe-tuto {
      display: none; } }
  .swipe-tuto span {
    padding: 0 16px 0 0; }
  .swipe-tuto img {
    width: 16px;
    height: 16px; }

.currency-section {
  margin-bottom: 60px; }

.illustration-decoration {
  position: relative;
  width: 100%;
  opacity: .1; }
  @media (min-width: 768px) {
    .illustration-decoration {
      width: 800px;
      height: 600px; } }

.announcement-illustration-decoration {
  position: absolute;
  bottom: -200px;
  width: 100%;
  opacity: .1; }
  @media (min-width: 768px) {
    .announcement-illustration-decoration {
      width: 800px;
      height: 600px; } }

.illustration-decoration-full {
  position: relative;
  width: 100%;
  opacity: .4; }

.is-text-centered {
  text-align: center; }

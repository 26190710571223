.module-hours {
	margin-top: 40px;
	overflow: hidden;
	background-color: $primary;
	height: 330px;
	text-align: center;

	@media (min-width: $breakpoint-tablet) {
		//margin-top: 80px
		height: 680px;
		text-align: left; }

	[data-anim-hours] {
		display: none;

		@media (min-width: $breakpoint-tablet) {
			background-size: 70% 100%;
			opacity: .5;
			background-position: center;
			display: block; }
		@media (min-width: $breakpoint-desktop) {
			background-size: 50% 100%; } } }

.clock {
	margin: 0 auto;
	transform: translateY(-64px);
	width: 300px;
	height: 300px;
	position: relative;
	border-radius: 50%;
	background: $white;
	border: 3px solid $secondary;
	@include defaultBoxShadow();
	overflow: hidden;
	position: relative;
	z-index: 3200;
	@media (min-width: $breakpoint-tablet) {
		transform: translateY(-200px); }
	.layer {
		position: absolute;
		top: 0;
		left: 0;
		width: 300px;
		height: 300px;
		transform-origin: 50% 50%;

		&.base {
			background: url(../../images/clock/base.svg);
			z-index: 100; }

		&.hours {
			background: url(../../images/clock/hours.svg);
			z-index: 110; }

		&.minutes {
			background: url(../../images/clock/minutes.svg);
			z-index: 120; }

		&.seconds {
			z-index: 130; } } }

[data-change-fill] {
	fill: $secondary; }

.form {}
.field-group {
	@include displayFlex;
	@include flexAlignCenter;
	@include flexWrap;


	label {
		width: 100%;
		display: none; }

	.field-input {
		background: $white;
		outline: none;
		padding: 16px;
		color: $primary;
		border: 0;
		width: 100%;
		margin-bottom: 16px;
		border-radius: 0; }

	.field-textarea {
		background: $white;
		outline: none;
		padding: 16px;
		color: $primary;
		border: 0;
		width: 100%;
		margin-bottom: 16px;
		color: $font-color;
		border-radius: 0; }

	button {
		padding: 8px 16px;
		background: $secondary;
		border: 0;
		text-shadow: 0;
		outline: none;
		color: $white;
		border-radius: 0;
		transition: .2s all ease-in-out;
		&:hover {
			transition: .2s all ease-in-out;
			background: $white;
			color: $secondary; } } }

nav {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 4000;
	height: 48px;
	background: rgba($white, 1);
	@media (min-width: $breakpoint-desktop) {
		transform: translateY(0)!important; }

	.navbar {
		position: relative;
		height: 100%;
		@include displayFlex();
		@include flexAlignStart();
		@include flexNoWrap();
		@include flexJustifyStart();
		background: $white;
		transition: .3s all ease-in;
		@media (min-width: $breakpoint-tablet) {
			@include flexJustifyBetween();
			transition: .3s all ease-in; } }
	.brand {
		//background: $primary
		// position: absolute
		// top: 0
		// left: 0
		//display: none
		// width: 180px
		// height: 77px
		//padding: 16px
		//width: 87px
		height: 48px;
		width: auto;
		overflow: hidden;
		cursor: pointer;
		transition: .2s all ease-in;
		width: 160px;
		//@media (min-width: $breakpoint-tablet)
		//	display: block
		/// position: absolute
		/// left: 16px
		&:hover {
			width: 180px;
			transition: .2s all ease-in;
			img {
				//transform: rotate(0deg)
 } }				//transition: .2s all ease-in
		img {
			//width: 148px
			width:  auto;
			padding: 8px;
			height: 48px;
			//transform: rotate(-90deg)
			//transform-origin: 14%
 } } }			//transition: .2s all ease-in
.menu-mobile {
	// padding: 10px 15px
	position:  absolute;
	// top: 0
	right: 0;
	width: 48px;
	height: 48px;
	cursor: pointer;
	@media (min-width: $breakpoint-desktop) {
		display: none; }

	&:hover {
		.bar[data-type="1"] {

			transition: all .2s ease; }

		.bar[data-type="1"] {

			transition: all .2s ease; }

		.bar[data-type="3"] {

			transition: all .2s ease; }

		.bar[data-type="3"] {

			transition: all .2s ease; } }

	.bars {
		position: absolute;
		width: 24px;
		height: 16px;
		top: 16px;
		left: 12px;
		.bar {
			width: 100%;
			height: 2px;
			background: $primary;
			position: absolute;
			transition: all .2s ease;
			transform-origin: 50% 50%;
			&[data-type="1"] {
				top: 0;
				left: 0; }
			&[data-type="2"] {
				top: 6px;
				left: 0; }

			&[data-type="3"] {
				top: 12px;
				left: 0; } } } }




.primary-navigation {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 290px;
	background: $white;
	text-align: center;
	transform: translateY(-290px);
	@include displayFlex();
	@include flexAlignCenter();
	@include flexWrap();
	@include flexJustifyEnd();
	text-align: center;
	@media (min-width: $breakpoint-desktop) {
		height: 100%;
		position: relative;
		transform: translateY(0);
		padding: 0; }
	li {
		display: inline-block;
		padding: 8px 0 8px 16px;
		width: 100%;
		cursor: pointer;
		@media (min-width: $breakpoint-desktop) {
			width: auto;
			padding: 0 16px;
			@include displayFlex();
			@include flexAlignCenter();
			height: 100%; }

		.button {
			cursor: pointer;
			padding: 8px 32px;
			height: 100%;
			background: $primary;
			border: 0;
			width: auto;
			text-shadow: 0;
			outline: none;
			color: $white;
			border-radius: 0;
			transition: .2s all ease-in-out;
			text-align: center;
			display: inline-block;
			box-shadow: 0 3px 23px rgba(0,0,0,.4);
			@include flexAlignCenter();
			&:hover {
				transition: .2s all ease-in-out;
				color: $secondary;
				background: $white; }

			@media (min-width: $breakpoint-desktop) {
				@include displayFlex(); } } } }

.secondary-navigation {
	width: 100%;
	background: rgba($white, .6)pcontac;
	padding: 8px 0;
	z-index: 6500;
	left: 0;
	ul {
		@include displayFlex();
		@include flexAlignCenter();
		@include flexWrap();
		@include flexJustifyStart(); }
	li {
		display: inline-block;
		padding: 0 16px; }


	.arrow-left {
		width: 32px;
		height: 32px; } }

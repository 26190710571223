.award {
	width: 100%;
	@include displayFlex;
	@include flexJustifyBetween;
	@include flexNoWrap;
	padding: 16px 0;
	@media (min-width: $breakpoint-tablet) {
			@include flexJustifyStart;
			@include flexWrap; }
	.label {
		color: $secondary;
		text-align: left;
		width: 100%;
		margin-bottom: 40px;
		@media (min-width: $breakpoint-tablet) {
			text-align: center; } }
	.image {
		padding: 16px;
		min-width: 160px;
		img {
			width: 100%; } } }


.market-item {
	cursor: pointer;
	@include displayFlex;
	@include flexAlignCenter;
	@include flexNoWrap;
	@include flexJustifyBetween;
	padding: 16px 0;
	opacity: .4;
	border-bottom: 1px solid transparent;
	transition: .2s all ease-in;
	@media (min-width: $breakpoint-tablet) {
			@include flexWrap; }
	@media (min-width: $breakpoint-desktop) {
			@include flexNoWrap; }
	&.is-open {
		opacity: 1;
		transition: .2s all ease-in; }
	&.is-active {
		border-bottom: 1px solid $white;
		transition: .2s all ease-in; }
	&:hover {
		border-bottom: 1px solid rgba($white, .5);
		transition: .2s all ease-in; }

	.label {
		font-weight: 600; } }


.name {
	position: relative;
	z-index: 1500;
	text-align: center;
	color: $white;
	font-weight: 800;
	background: rgba($primary, .3);
	padding: 6px;
	display: inline-block; }

.management-names {
	position: absolute;
	bottom: 100px;
	left: 0;
	z-index: 1500;
	text-align: center;
	font-weight: 800;
	width: 100%;
	min-width: 1200px;
	.g-25 {
		width: 25%; } }

.team-image {
	height: 680px;
	min-width: 1200px;
	width: 100%; }

.manager-biography {
	p {
		margin-bottom: 16px; } }

.management-cta {
	position: absolute;
	bottom: 60px;
	left: 0;
	z-index: 1652;
	width: 100%;
	text-align: center;
	.button {
		cursor: pointer;
		padding: 16px 32px;
		background: $white;
		border: 0;
		width: auto;
		text-shadow: 0;
		outline: none;
		color: $primary;
		border-radius: 0;
		transition: .2s all ease-in-out;
		text-align: center;
		display: inline-block;
		&:hover {
			transition: .2s all ease-in-out;
			color: $secondary; } } }

.swipe-tuto {
	background: rgba($primary, .6);
	color: $white;
	position: absolute;
	padding: 8px;
	bottom: 7px;
	right: 0;
	@include displayFlex;
	@include flexAlignCenter;

	@media (min-width: $breakpoint-desktop) {
		display: none; }
	span {
		padding: 0 16px 0 0; }
	img {
		width: 16px;
		height: 16px; } }

.module-contact {

	@include displayFlex;
	@include flexAlignCenter;
	@include flexNoWrap;
	color: $white;
	position: relative;
	z-index: 2700;

	.image {
		width: 64px;
		max-width: 64px;
		min-width: 64px;
		height: 64px;
		border-radius: 50%;
		background-size: 100%;
		background-position: center;
		margin-right: 16px;
		flex: 1; }
	.contact {
		flex: 1;
		li {
			word-break: normal;
			padding: 4px 0; } }
	@media (min-width: $breakpoint-tablet) {
		@include flexWrap;
		.image {
			width: 100%;
			margin-bottom: 8px; }
		.contact {
			flex: 100%; } } }

.primary-contact {
	@include displayFlex;
	@include flexAlignCenter;
	@include flexJustifyStart;
	padding: 16px;
	width: 100%;
	min-width: 360px;
	max-width: 420px;
	background: $primary;
	@include flexWrap;
	@include defaultBoxShadow;
	//transform: translateX(16px) translateY(-32px)

	@media (min-width: $breakpoint-tablet) {
		transform: translateX(-32px) translateY(-32px);
		@include flexNoWrap;
		width: auto;
		min-width: 360px;
		max-width: 420px; }

	@media (min-width: $breakpoint-desktop) {
		transform: translateX(-32px) translateY(-64px); } }

.secondary-contact {
	margin-bottom: 16px;
	.image {
		@include defaultBoxShadow; } }

.default-contact {
	margin-bottom: 16px;
	color: $font-color;
	@include flexJustifyStart;
	margin-bottom: 16px;
	@media (min-width: $breakpoint-tablet) {
		margin-bottom: 40px; }
	.contact-role {
		font-family: 'billcorpnar-semi-bold';
		color: $secondary;
		font-size: 0.875em; } }

.jobs-offer {
	display: flex;
	justify-content: space-between; }

.job-offer {
	position: relative;
	background: $white;
	@include defaultBoxShadow;
	padding: 16px;
	@media (min-width: $breakpoint-tablet) {
		padding: 32px; }
	.contact {
		position: relative; }

	.cta {
		position: relative;
		right: 0;
		bottom: 0;
		display: flex;
		align-items: center;
		margin-top: 8px;
		@media (min-width: $breakpoint-tablet) {
			position: absolute {
		    margin-top: 0; } } }


	.icon-linkedin {
		height: 20px;
		img {
			width: auto;
			height: 100%; } }
	.button {
		background: $primary;
		padding: 0 16px;
		border-radius: 4px;
		font-size: 12px;
		color: #FFF;
		font-weight: bold;
		line-height: 1em;
		height: 25px;
		display: flex;
		align-items: center;
		padding-top: 2px; } }

.contact-select {
	height: 53px;
	border-radius: 0;
	-webkit-appearance: none;
	-webkit-border-radius: 0px; }

footer {
	width: 100%;
	@include displayFlex;
	@include flexAlignCenter;
	@include flexJustifyBetween;
	@include flexNoWrap;
	border-top: 1px solid rgba($secondary, .5);
	margin-top: 32px!important;
	padding: 16px 0;
	margin-bottom: 64px;

	@media (min-width: $breakpoint-tablet) {
		padding: 32px 0; }

	.nav {
		ul {
			li {
				display: inline-block;
				padding: 0 0 16px 0;
				width: 100%;
				@media (min-width: $breakpoint-tablet) {
					width: auto;
					padding: 0 8px 0 0; } } } }
	.social {
		ul {
			li {
				display: inline-block;
				padding: 0  0 0 8px; } } }
	.gottex {
		font-weight: 800; }

	.social {
		img {
			width: 32px;
			height: 32px; } } }

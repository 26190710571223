.alert {
	margin-bottom: 16px; }

.alert-success {}


.alert-error {
	color: $red; }

.alert-info {}


.alert-warning {}


.alert-loading {}

.currency-section {
	margin-bottom: 60px; }

.illustration-decoration {
	position: relative;
	width: 100%;
	opacity: .1;
	@media (min-width: $breakpoint-tablet) {
		width: 800px;
		height: 600px; } }

.announcement-illustration-decoration {
	position: absolute;
	bottom: -200px;
	width: 100%;
	opacity: .1;
	@media (min-width: $breakpoint-tablet) {
		width: 800px;
		height: 600px; } }

.illustration-decoration-full {
	position: relative;
	width: 100%;
	opacity: .4; }

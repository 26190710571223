html {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box; }

*, *:before, *:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit; }

body {
	background-color: $white;
	font-family: 'billcorpnar-book', sans-serif!important;
	font-weight: 400;
	line-height: 1.2em;
	color: $font-color;
	font-size: 16px;
	-webkit-font-smoothing: antialiased;
	overflow: hidden; }

@mixin defaultContainer() {
	position: relative;
	max-width: $breakpoint-desktop;
	margin: 0 auto;
	width: 100%;
	padding: 0 8px;
	@media (min-width: $breakpoint-tablet) {
		padding: 0 16px; }
	@media (min-width: $breakpoint-desktop) {
		padding: 0; } }


@mixin displayFlex() {
	display: flex;
	display: -ms-flexbox; }


@mixin flexAlignCenter() {
	align-items: center;
	-webkit-align-items: center;
	-ms-flex-align: center; }

@mixin flexAlignStart() {
	align-items: flex-start;
	-webkit-align-items: flex-start;
	-ms-flex-align: flex-start; }


@mixin flexJustifyStart() {
	justify-content: start;
	-webkit-justify-content: start {
  	-ms-flex-pack: start; } }

@mixin flexJustifyCenter() {
	justify-content: center;
	-webkit-justify-content: center {
  	-ms-flex-pack: center; } }

@mixin flexJustifyBetween() {
	justify-content: space-between;
	-webkit-justify-content: space-between;
	-ms-flex-pack: space-between; }

@mixin flexJustifyAround() {
	justify-content: space-around;
	-webkit-justify-content: space-around;
	-ms-flex-pack: space-around; }

@mixin flexJustifyEnd() {
	justify-content: flex-end;
	-webkit-justify-content: flex-end;
	-ms-flex-pack: flex-end; }

@mixin flexWrap() {
	flex-wrap: wrap;
	-webkit-flex-wrap: wrap; }

@mixin flexNoWrap() {
	flex-wrap: nowrap;
	-webkit-flex-wrap: nowrap; }

@mixin sectionMargin() {
	margin-bottom: 30px;
	@media (min-width: $breakpoint-tablet) {
		margin-bottom: 75px; }
	@media (min-width: $breakpoint-desktop) {
		margin-bottom: 115px; } }

@mixin defaultBoxShadow() {
	box-shadow: 0 0 20px rgba(0, 0, 0, .30); }

@mixin lightBoxShadow() {
	box-shadow: 0 0 20px rgba(0, 0, 0, 0.16); }

@mixin textShadow() {
	text-shadow: 0 0 20px rgba(0, 0, 0, 0.16); }


main {
	height: 100vh;
	overflow-x: hidden;
	overflow-y: scroll;
	-webkit-overflow-scrolling: touch; }


.default-container {
	@include defaultContainer(); }

.is-hidden {
	display: none !important; }

.is-overflow-hidden {
	overflow: hidden!important; }

.is-mobile-visible {
	display: auto;
	@media (min-width: $breakpoint-tablet) {
		display: none; } }


.is-text-right {
	text-align: right; }

.is-text-center {
	text-align: center; }



[data-href] {
	cursor: pointer; }

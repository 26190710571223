.blog-item {
	margin-bottom: 40px;
	padding-right: 0;
	@media (min-width: $breakpoint-tablet) {
		padding-right: 32px; }
	@media (min-width: $breakpoint-desktop) {
		padding-right: 60px; }

	.download-link {
		margin-top: 16px;
		display: inline-block; } }

.experience-selection {
	position: absolute;
	bottom: 0;
	right: 0;
	background: $white;
	z-index: 2000;
	text-align: center;
	padding: 0;
	box-shadow: 0 -6px 16px rgba(0,0,0,.23);
	font-size: 0.875em;
	font-weight: 800;
	@include flexNoWrap;
	@include displayFlex;
	@include flexJustifyStart;
	transform: rotate(90deg) translateY(17px);
	width: 680px;
	height: 32px;
	transform-origin: 100% 100%;
	background: transparent;
	box-shadow: none;
	color: $primary;
	padding-left: 64px;
	@media (min-width: $breakpoint-tablet) {
		position: fixed;
		transform: rotate(90deg) translateY(48px);
		width: 100vh; } }

.switch-button {
	cursor: pointer;
	padding: 0;
	margin: 0;
	border: 0;
	transform: rotate(0);
	cursor: pointer;
	@include displayFlex;
	@include flexAlignCenter;
	@include flexJustifyStart;
	height: 32px;
	padding: 16px;
	width: auto;
	transition: all .3s ease-in-out;
	text-align: center;
	margin-right: 16px;
	position: relative;
	&::after {
		content: '';
		position: absolute;
		width: 0%;
		height: 4px;
		background: rgba($red, .5);
		bottom: 0;
		left: 0;
		transition: .2s all ease-in-out; }

	&[data-trigger-switzerland] {
		&:after {
			background: rgba($red, .5); } }

	&[data-trigger-sweden] {
		&:after {
			background: rgba($yellow, .5); } }


	&:hover,
	&.is-active {
		&[data-trigger-switzerland] {
			&:after {
				// background: $white
				// height: 40px
				// transition: all .2s ease-in-out
				// //color: $white
				// //@include defaultBoxShadow

				width: 100%;
				transition: .2s all ease-in-out; } }
		&[data-trigger-sweden] {
			&:after {
				// background: $white
				// height: 40px
				// transition: all .2s ease-in-out
				// //color: $white
				width: 100%;
				transition: .2s all ease-in-out; } } }
	span {
		display: none;
		padding: 0 6px; } }

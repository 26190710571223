.primary-module-text {
	position: relative;
	z-index: 2000;
	padding: 32px;
	margin-top:  -200px;
	background: $primary;
	color: $white;
	@include defaultBoxShadow;
	@media (min-width: $breakpoint-tablet) {
		padding: 40px; }

	.left-part {
		padding: 0 0 8px 0;
		@media (min-width: $breakpoint-tablet) {
			padding-right: 16px; } }

	.right-part {
		padding: 0 0 8px 0;
		@media (min-width: $breakpoint-tablet) {
			padding-left: 16px; } } }

.secondary-module-text {
	position: relative;
	z-index: 2000;
	padding: 32px;
	margin-top:  -200px;
	margin-bottom: 16px;
	@include defaultBoxShadow;
	@media (min-width: $breakpoint-tablet) {
		margin-bottom: 80px;
		padding: 40px; }

	color: $white;
	p {
		margin-bottom: 16px; }

	&[data-anim-sweden] {
		background: $yellow; }
	&[data-anim-switzerand] {
		background: $secondary; }

	.left-part {
		padding: 0 0 8px 0;
		@media (min-width: $breakpoint-tablet) {
			padding-right: 16px; } }

	.right-part {
		padding: 0 0 8px 0;
		@media (min-width: $breakpoint-tablet) {
			padding-left: 16px; } } }

.default-module-text {
	margin-bottom: 40px;
	p {
		margin-bottom: 16px; }

	ul {
		&.default-list {
			li {
				padding: 0;
				margin: 0;
				position: relative;
				padding-left: 16px;
				padding-bottom: 8px;
				&:after {
					content: '';
					position: absolute;
					top: 8px;
					left: 0;
					width: 4px;
					height: 4px;
					background: $primary;
					border-radius: 50%; } } }
		&.two-cols-list {
			li {
				width: 48%;
				display: inline-block; } } }

	.left-part {
		padding: 0 0 32px 0;
		@media (min-width: $breakpoint-tablet) {
			padding-right: 16px; } }

	.middle-part {
		padding: 0 0 32px 0;
		@media (min-width: $breakpoint-tablet) {
			padding-right: 16px;
			padding-left: 16px; } }

	.right-part {
		padding: 0 0 32px 0;
		@media (min-width: $breakpoint-tablet) {
			padding-left: 16px; } } }


.third-module-text {
	position: relative;
	z-index: 2000;
	padding: 40px;
	background: $primary;
	color: $white;
	margin-right: 0;
	margin-bottom: 40px;
	@include defaultBoxShadow;
	@media (min-width: $breakpoint-tablet) {
		margin-right: 32px; }
	.left-part {
		padding: 0 0 8px 0;
		@media (min-width: $breakpoint-tablet) {
			padding-right: 16px; } }

	.right-part {
		padding: 0 0 8px 0;
		@media (min-width: $breakpoint-tablet) {
			padding-left: 16px; } }

	p {
		margin-bottom: 16px; }

	ul.default-list {
		li {
			padding: 0;
			margin: 0;
			position: relative;
			padding-left: 16px;
			padding-bottom: 8px;
			&:after {
				content: '';
				position: absolute;
				top: 8px;
				left: 0;
				width: 4px;
				height: 4px;
				background: $white;
				border-radius: 50%; } } } }

.fourth-module-text {
	position: relative;
	z-index: 2000;
	padding: 40px;
	margin-top:  0;
	background: $primary;
	color: $white;
	@include defaultBoxShadow;
	@media (min-width: $breakpoint-tablet) {
		margin-top: -200px; }
	.left-part {
		padding: 0 0 8px 0;
		@media (min-width: $breakpoint-tablet) {
			padding-right: 16px; } }

	.right-part {
		padding: 0 0 8px 0;
		@media (min-width: $breakpoint-tablet) {
			padding-left: 16px; } } }

.module-newsletter {
	position: relative;
	z-index: 1400;
	padding: 32px;
	background: $primary;
	margin-top: -20px;
	color: $white;
	@include defaultBoxShadow;
	@media (min-width: $breakpoint-desktop) {
		padding: 40px; }

	h3 {
		margin-bottom: 16px; } }

.mc-field-group {
	@include displayFlex;
	@include flexAlignCenter;
	@include flexNoWrap;
	border-radius: 0;
	label {
		display: none; }

	input[type="email"] {
		background: $white;
		outline: none;
		padding: 16px;
		color: $primary;
		border: 0;
		flex: 2;
		border-radius: 0; }

	input[type="submit"] {
		outline: none;
		border: 0;
		padding: 16px 8px;
		background: $secondary;
		flex: 1;
		font-weight: 800;
		font-size: 16px;
		border-radius: 0;
		transition: .2s all ease-in-out;
		&:hover {
			transition: .2s all ease-in-out;
			background: $white;
			color: $secondary; } } }

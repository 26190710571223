.module-image {
	position: relative;
	height: 680px;
	border-left: 16px solid $white;
	border-right: 16px solid $white;
	@media (min-width: $breakpoint-tablet) {
		border-left: 32px solid $white;
		border-right: 32px solid $white; }

	.backgrounds-image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		overflow: hidden; }
	.background-image {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 1200;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;

		&.background-primary {
			background-color: $primary; } }

	.brush-image {
		position: absolute;
		bottom: 0;
		left: 0;
		height: 34px;
		width: 100%;
		background-size: 200px;
		background-position: bottom left;
		background-repeat: repeat-x;
		z-index: 1250;
		&.brush-top {
			bottom: auto;
			top: 0; }
		&.brush-primary {
			height: 300px;
			width: 100%;
			background-size: 600px; }
		&.brush-click-throught {
			pointer-events: none; } }
	.header-content {
		padding-top: 200px;
		z-index: 	1300; } }

.primary-module-image {
	border-top: 48px solid $white; }

.secondary-module-image {
	border-top: 48px solid $white;
	height: 400px;
	text-align: center;
	@media (min-width: $breakpoint-tablet) {
		height: 680px;
		text-align: left; } }


.bg-homepage-header-switzerland {
	background-image: url(../../images/background/switzerland/header@mobile.jpg);
	@media (min-width: $breakpoint-tablet) {
		background-image: url(../../images/background/switzerland/header@tablet.jpg); }

	@media (min-width: $breakpoint-desktop) {
		background-image: url(../../images/background/switzerland/header@desktop.jpg); }

	@media (min-width: $breakpoint-widescreen) {
		background-image: url(../../images/background/switzerland/header@widescreen.jpg); } }


.bg-homepage-header-sweden {
	background-image: url(../../images/background/sweden/header@mobile.jpg);
	@media (min-width: $breakpoint-tablet) {
		background-image: url(../../images/background/sweden/header@tablet.jpg); }

	@media (min-width: $breakpoint-desktop) {
		background-image: url(../../images/background/sweden/header@desktop.jpg); }

	@media (min-width: $breakpoint-widescreen) {
		background-image: url(../../images/background/sweden/header@widescreen.jpg); } }


.bg-homepage-switzerland {
	background-image: url(../../images/background/switzerland/about@mobile.jpg);
	@media (min-width: $breakpoint-tablet) {
		background-image: url(../../images/background/switzerland/about@tablet.jpg); }

	@media (min-width: $breakpoint-desktop) {
		background-image: url(../../images/background/switzerland/about@desktop.jpg); }

	@media (min-width: $breakpoint-widescreen) {
		background-image: url(../../images/background/switzerland/about@widescreen.jpg); } }

.bg-homepage-zurich {
	background-image: url(../../images/background/zurich/about@mobile.jpg);
	@media (min-width: $breakpoint-tablet) {
		background-image: url(../../images/background/zurich/about@tablet.jpg); }

	@media (min-width: $breakpoint-desktop) {
		background-image: url(../../images/background/zurich/about@desktop.jpg); }

	@media (min-width: $breakpoint-widescreen) {
		background-image: url(../../images/background/zurich/about@widescreen.jpg); } }


.bg-homepage-sweden {
	background-image: url(../../images/background/sweden/about@mobile.jpg);
	@media (min-width: $breakpoint-tablet) {
		background-image: url(../../images/background/sweden/about@tablet.jpg); }

	@media (min-width: $breakpoint-desktop) {
		background-image: url(../../images/background/sweden/about@desktop.jpg); }

	@media (min-width: $breakpoint-widescreen) {
		background-image: url(../../images/background/sweden/about@widescreen.jpg); } }

.bg-homepage-norway {
	background-image: url(../../images/background/norway/header@mobile.jpg);
	@media (min-width: $breakpoint-tablet) {
		background-image: url(../../images/background/norway/header@tablet.jpg); }

	@media (min-width: $breakpoint-desktop) {
		background-image: url(../../images/background/norway/header@desktop.jpg); }

	@media (min-width: $breakpoint-widescreen) {
		background-image: url(../../images/background/norway/header@widescreen.jpg); } }


.bg-homepage-team {
	background-image: url(../../images/background/team@tablet.jpg);

	@media (min-width: $breakpoint-desktop) {
		background-image: url(../../images/background/team@desktop.jpg); }

	@media (min-width: $breakpoint-widescreen) {
		background-image: url(../../images/background/team@widescreen.jpg); } }

.bg-contact-header {
	background-image: url(../../images/background/switzerland/about@mobile.jpg);
	@media (min-width: $breakpoint-tablet) {
		background-image: url(../../images/background/switzerland/about@tablet.jpg); }

	@media (min-width: $breakpoint-desktop) {
		background-image: url(../../images/background/switzerland/about@desktop.jpg); }

	@media (min-width: $breakpoint-widescreen) {
		background-image: url(../../images/background/switzerland/about@widescreen.jpg); } }

.bg-team-header {
	background-image: url(../../images/background/team@tablet.jpg);

	@media (min-width: $breakpoint-desktop) {
		background-image: url(../../images/background/team@desktop.jpg); }

	@media (min-width: $breakpoint-widescreen) {
		background-image: url(../../images/background/team@widescreen.jpg); } }

.bg-switzerland-header {
	background-image: url(../../images/background/switzerland/market@mobile.jpg);
	@media (min-width: $breakpoint-tablet) {
		background-image: url(../../images/background/switzerland/market@tablet.jpg); }

	@media (min-width: $breakpoint-desktop) {
		background-image: url(../../images/background/switzerland/market@desktop.jpg); }

	@media (min-width: $breakpoint-widescreen) {
		background-image: url(../../images/background/switzerland/market@widescreen.jpg); } }

.bg-blog-header {
	background-image: url(../../images/background/sweden/header@mobile.jpg);
	@media (min-width: $breakpoint-tablet) {
		background-image: url(../../images/background/sweden/header@tablet.jpg); }

	@media (min-width: $breakpoint-desktop) {
		background-image: url(../../images/background/sweden/header@desktop.jpg); }

	@media (min-width: $breakpoint-widescreen) {
		background-image: url(../../images/background/sweden/header@widescreen.jpg); } }

.bg-fixing-header {
	background-image: url(../../images/background/blog@mobile.jpg);
	@media (min-width: $breakpoint-tablet) {
		background-image: url(../../images/background/blog@tablet.jpg); }

	@media (min-width: $breakpoint-desktop) {
		background-image: url(../../images/background/blog@desktop.jpg); }

	@media (min-width: $breakpoint-widescreen) {
		background-image: url(../../images/background/blog@widescreen.jpg); } }

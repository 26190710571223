.module-loader {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height:  100%;
	overflow: hidden;
	background-position: center;
	background-size: 200px;
	background-repeat: no-repeat;
	background-color: $primary;
	z-index: 8000;
	padding: 16px;
	@include displayFlex;
	@include flexNoWrap;
	@include flexJustifyCenter;

	svg {
		width: 400px;
		fill: $white; }
	.color-brand {
		fill: $secondary; } }
main {
	//opacity: 0
	//height: 100vh
 }	//overflow: hidden

[data-loader-anim-path] {
	opacity: 0; }
